<template>
  <div
    class="
      p-2
      md:p-4
      sm:p-2
      h-16
      w-16
      md:h-28
      md:w-28
      sm:h-24
      sm:w-24
      rounded-full
      shadow-md
      flex
      justify-center
      items-center
      m-1
      bg-white
      border-none
    "
  >
    <img :src="src" />
  </div>
</template>

<script>
export default {
  props: ["src"],
};
</script>