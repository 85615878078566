<template>
  <div
    :class="[color]"
    class="
      inline-block
      text-center
      px-4
      py-3
      my-2
      text-base
      font-bold
      leading-normal
      text-white
      no-underline
      align-middle
      rounded-full
      select-none
      sm:text-sm
      md:text-lg
      lg:text-xl
      lg:py-3
      lg:px-8
      hover:bg-black
      hover:text-white
      shadow-sm
    "
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["color"],
};
</script>