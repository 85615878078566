<template>
  <div class="home bg-peach">
    <TopBar></TopBar>
    <MainSection msg="Welcome to Your Vue.js App" />
    <ColorfulSection></ColorfulSection>

    <FeaturesSection></FeaturesSection>
    <!-- <Security></Security> -->
    <Cta></Cta>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import TopBar from "@/components/TopBar.vue";

import MainSection from "@/components/MainSection.vue";
import ColorfulSection from "@/components/ColorfulSection.vue";
import FeaturesSection from "@/components/FeaturesSection.vue";
import Cta from "@/components/Cta.vue";
// import Security from "@/components/Security.vue";

import Footer from "@/components/Footer.vue";
export default {
  name: "Home",
  components: {
    TopBar,
    MainSection,
    ColorfulSection,
    FeaturesSection,
    // Security,
    Cta,
    Footer,
  },
};
</script>
