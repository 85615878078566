<template>
  <!-- Section 1 -->
  <div>
    <!-- Section 1 -->
    <section class="w-full bg-gray-50">
      <nav
        class="
          box-border
          flex
          items-center
          justify-between
          w-full
          h-20
          px-6
          mx-auto
          my-0
          leading-6
          text-gray-800
          max-w-7xl
        "
      >
        <a
          href="#_"
          class="
            flex
            items-center
            font-medium
            text-gray-900
            lg:w-auto
            title-font
            lg:items-center
            lg:justify-center
            md:mb-0
          "
        >
          <span
            class="
              flex
              justify-between
              text-xl
              font-black
              leading-none
              text-gray-900
              select-none
            "
          >
            <!-- <img
              class="h-8 mr-2"
              src="https://da2nz7dzzdx58.cloudfront.net/fostrum-icon.png"
            /> -->
            <!-- <img class="w-36" src="../assets/fostrum-logo.png" /> -->
            <span
              class="
                text-3xl
                relative
                font-black
                text-transparent
                bg-center bg-cover bg-gradient-to-br
                from-indigo-400
                via-indigo-600
                to-indigo-500
                bg-clip-text
              "
              style="
                background-image: url('https://da2nz7dzzdx58.cloudfront.net/bg-colorful.jpeg');
              "
            >
              fostrum
            </span>
          </span>
        </a>
        <div class="relative flex list-none lg:block">
          <ul
            class="
              top-0
              left-0
              z-10
              flex-wrap
              items-center
              w-full
              h-full
              p-0
              space-y-5
              leading-6
              text-gray-800
              lg:space-x-5
              lg:static
              lg:w-auto
              lg:flex
              lg:space-y-0
              lg:mt-0
            "
          >
            <!-- <li class="box-border w-full my-3 text-lg text-center lg:w-auto">
              <a
                href="https://app.fostrum.com"
                target="_blank"
                class="
                  inline-block
                  px-6
                  py-3
                  text-sm
                  font-bold
                  leading-normal
                  text-black
                  no-underline
                  align-middle
                  bg-yellow-400
                  rounded-full
                  cursor-pointer
                  select-none
                  lg:py-3
                  lg:px-8
                  hover:bg-black
                  hover:text-white
                  shadow-sm
                "
                >Sign Up
              </a>
            </li> -->
            <li class="box-border w-full my-3 text-lg text-center lg:w-auto">
              <a
                href="https://app.fostrum.com"
                target="_blank"
                class="
                  inline-block
                  px-6
                  py-3
                  text-sm
                  font-bold
                  leading-normal
                  text-black
                  no-underline
                  align-middle
                  bg-yellow-400
                  rounded-full
                  cursor-pointer
                  select-none
                  lg:py-3
                  lg:px-8
                  hover:bg-black
                  hover:text-white
                  shadow-sm
                "
                >Login
              </a>
            </li>
          </ul>
        </div>
        <!--  -->
      </nav>

      <div
        class="
          box-border
          flex-grow
          w-full
          px-10
          pt-8
          pb-12
          mx-auto
          leading-6
          text-gray-800
          lg:pt-16
          lg:pb-20
          max-w-7xl
        "
      >
        <div
          class="
            relative
            w-full
            px-0
            mb-12
            leading-6
            text-left
            md:text-center
            lg:flex-grow-0 lg:flex-shrink-0
          "
        >
          <h1
            class="
              box-border
              mt-0
              mb-4
              font-black
              text-gray-800 text-3xl
              md:text-4xl
              lg:text-5xl
              xl:text-5xl
            "
          >
            Financial <span class="gradient-blue">control center</span> for your
            <span
              class="
                relative
                font-black
                text-transparent
                bg-center bg-cover bg-gradient-to-br
                from-indigo-400
                via-indigo-600
                to-indigo-500
                bg-clip-text
              "
              style="
                background-image: url('https://cdn.devdojo.com/images/february2021/bg-colorful.jpg');
              "
            >
              creator
            </span>
            business
            <!-- <span class="font-black text-indigo-600">Business</span>. -->
          </h1>
          <p
            class="
              box-border
              mt-0
              text-base
              font-normal
              text-gray-800
              opacity-90
              lg:text-xl
              xl:text-2xl
            "
          >
            Managing money doesn’t have to be a chore, instantly see how you’re
            doing across everything
          </p>
          <div class="box-border text-left text-gray-800 md:text-center">
            <!-- <a
              class="
                inline-block
                px-6
                py-3
                mt-5
                mb-5
                text-base
                font-bold
                leading-normal
                text-black
                no-underline
                align-middle
                bg-primary
                rounded-full
                cursor-pointer
                select-none
                md:text-xl
                lg:py-3
                lg:px-8
                hover:bg-black
                hover:text-white
                shadow-md
              "
              href="#_"
            >
              Get Started
            </a> -->
          </div>
        </div>
        <div class="text-center">
          <a
            href="https://app.fostrum.com"
            target="_blank"
            class="
              inline-block
              my-1
              px-6
              py-3
              text-lg
              lg:text-xl
              font-bold
              leading-normal
              text-black
              no-underline
              align-middle
              bg-yellow-400
              rounded-full
              cursor-pointer
              select-none
              lg:py-3
              lg:px-8
              hover:bg-black
              hover:text-white
              shadow-sm
            "
            >Sign Up
          </a>
        </div>
        <div
          class="
            relative
            flex-grow-0 flex-shrink-0
            w-full
            max-w-5xl
            p-0
            mx-auto
            mt-12
            overflow-hidden
            rounded-lg
            shadow-2xl
            lg:flex-grow-0 lg:flex-shrink-0
          "
        >
          <!-- src="https://cdn.devdojo.com/images/november2020/mockup-page.png" -->

          <img
            rel="preload"
            src="../assets/main-overview.png"
            class="box-border leading-6 text-gray-800 align-middle border-none"
          />
        </div>
        <div
          class="
            flex flex-col
            justify-center
            w-full
            mt-2
            space-y-3
            sm:space-x-2 sm:space-y-0
            sm:flex-row
            lg:mt-4
          "
        ></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "MainSection",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
