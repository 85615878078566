<template>
  <!-- Section 1 -->
  <section class="bg-peach">
    <div
      class="
        flex flex-col
        items-center
        justify-center
        mt-10
        px-5
        py-10
        mx-auto
        max-w-7xl
        md:px-0
      "
    >
      <div class="relative">
        <h1
          class="
            relative
            text-3xl
            md:text-4xl
            lg:text-5xl
            xl:text-5xl
            font-black
            text-transparent
            gradient-red
          "
        >
          Sign up & access today!
        </h1>
      </div>
      <!-- <p class="mt-3 text-xl text-gray-500 lg:text-2xl">
        Discover the power of your potential.
      </p> -->
      <div
        class="
          flex flex-col
          justify-center
          w-full
          mt-2
          space-y-3
          sm:space-x-2 sm:space-y-0
          sm:flex-row
          lg:mt-4
        "
      >
        <Btn
          @click.native="earlyAccesssURL()"
          color="bg-yellow-400"
          class="cursor-pointer"
          >Sign up</Btn
        >
      </div>
      <div class="sm:text-sm md:text-md lg:text-lg text-black">
        <div class="mt-8 text-center font-bold text-2xl">
          Your security & privacy is important to us
        </div>
        <div class="mt-4 text-center">
          Your data is secured by <span class="font-bold">AES 256</span> and
          <span class="font-bold">TLS</span>
          banking grade encryption
        </div>
        <!-- <div class="mt-4 mb-10 text-center">
          Your data will never be shared with anyone.
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import Btn from "./Btn.vue";
export default {
  components: {
    Btn,
  },
  methods: {
    earlyAccesssURL() {
      window.location.href = "https://app.fostrum.com";
    },
  },
};
</script>