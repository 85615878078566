<template>
  <div class="font-sans">
    <!-- Section 1 -->

    <!-- Section 1 -->

    <!-- Section 4 -->
    <section class="py-20 bg-gray-50 text-black">
      <div
        class="
          px-8
          sm:px-8
          md:px-10
          xl:px-12
          flex flex-col
          lg:max-w-screen-lg
          xl:max-w-screen-2xl
          mx-auto
          space-y-12
        "
      >
        <h1
          class="
            box-border
            mx-0
            mt-6
            mb-8
            text-3xl text-left
            md:text-center
            lg:text-center
            font-black
            md:text-4xl
            lg:text-5xl
            xl:text-5xl
            text-gray-800
          "
        >
          Fostrum brings it all together so you can stay on track
        </h1>
        <div class="flex flex-col mb-8 animated fadeIn lg:flex-row">
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            class="
              flex
              items-center
              mb-8
              lg:w-7/12
              order-last
              sm:order-last
              sm:px-10
            "
          >
            <img
              class="shadow-xl rounded-3xl"
              src="../assets/income.png"
              alt=""
            />
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            class="
              sm:px-10
              lg:mr-8
              flex flex-col
              justify-center
              mt-5
              mb-8
              md:mt-0
              lg:w-5/12
            "
          >
            <h3
              class="
                text-2xl text-left
                md:text-center
                lg:text-left
                md:text-3xl
                lg:text-4xl
                font-black
                text-gray-700
              "
            >
              Manage all your
              <span class="gradient-blue">income</span> streams
            </h3>
            <p
              class="
                mt-5
                md:mt-10
                lg:mt-15
                text-md
                md:text-md
                lg:text-lg
                xl:text-xl
                text-gray-700 text-left
                md:text-center
                lg:text-left
              "
            >
              We connect all your different
              <span class="font-bold text-purple-400"
                >social media channels</span
              >, <span class="font-bold text-red-400">bank accounts</span>,
              <span class="font-bold text-yellow-400">credit cards</span> &
              <span class="font-bold text-green-400">ecommerce apps</span> so
              you could run your business better.
            </p>

            <div class="mt-5 flex flex-wrap mx-auto">
              <IntegrationCircle
                src="https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_Logo_2017.svg"
              ></IntegrationCircle>

              <IntegrationCircle
                src="https://upload.wikimedia.org/wikipedia/commons/8/89/Etsy_logo.svg"
              >
              </IntegrationCircle>
              <IntegrationCircle
                class=""
                src="https://upload.wikimedia.org/wikipedia/commons/5/5a/Patreon_logomark.svg"
              >
              </IntegrationCircle>
              <IntegrationCircle
                class=""
                src="https://upload.wikimedia.org/wikipedia/commons/0/0e/Shopify_logo_2018.svg"
              >
              </IntegrationCircle>
              <IntegrationCircle
                src="https://upload.wikimedia.org/wikipedia/commons/c/ce/Twitch_logo_2019.svg"
              >
              </IntegrationCircle>
              <IntegrationCircle
                src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg"
              >
              </IntegrationCircle>
            </div>
            <div>
              <p
                class="
                  mt-5
                  md:mt-10
                  lg:mt-15
                  text-md
                  md:text-md
                  lg:text-lg
                  xl:text-xl
                  text-gray-700 text-left
                  md:text-center
                  lg:text-left
                  mb-10
                "
              >
                <span class="font-bold">Make money in both Canada & US?</span>
                No problem, we supports over 10,000 banks worldwide allowing you
                to grow your business across borders in the right direction
                regardless of where you are.
              </p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div class="flex flex-col mb-8 lg:flex-row animated fadeIn">
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            class="
              flex
              items-center
              mb-8
              lg:w-7/12
              lg:order-first
              sm:px-10
              order-last
            "
          >
            <img
              class="px-0 shadow-xl rounded-3xl"
              src="../assets/main-transactions.png"
              alt=""
            />
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            class="
              sm:px-10
              max-h-full
              flex flex-col
              justify-center
              mt-5
              mb-8
              md:mt-0
              lg:w-5/12
            "
          >
            <h3
              class="
                mt-5
                md:mt-10
                lg:mt-15
                text-2xl text-left
                md:text-center
                lg:text-right
                md:text-3xl
                lg:text-4xl
                font-black
                text-gray-700
              "
            >
              Discover your full <span class="gradient-blue">potential</span>
            </h3>
            <div
              class="
                mt-5
                md:mt-10
                lg:mt-15
                text-md
                md:text-md
                lg:text-lg
                xl:text-xl
                text-left
                md:text-center
                lg:text-right
              "
            >
              <div class="font-bold">
                "What was that one transaction that cost $40?"
              </div>
              <div class="font-bold">
                "How much did I spend on Starbucks across all my cards?"
              </div>
            </div>
            <p
              class="
                mt-5
                md:mt-10
                lg:mt-15
                lg:ml-8
                text-md
                md:text-md
                lg:text-lg
                xl:text-xl
                text-left
                md:text-center
                lg:text-right
                text-gray-600
                mb-10
              "
            >
              Search for everything without having to log into a dozen apps -
              <span class="font-bold text-purple-400">expenses</span>,
              <span class="font-bold text-red-400">receipts</span>,
              <span class="font-bold text-yellow-400">payments</span>,
              <span class="font-bold text-green-400">notes</span>,
              <span class="font-bold text-blue-400">categories</span> & even
              more
            </p>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div class="flex flex-col mb-8 animated fadeIn lg:flex-row">
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            class="flex items-center mb-8 lg:w-7/12 order-last sm:px-10"
          >
            <img
              class="shadow-xl rounded-3xl"
              src="../assets//analytics.png"
              alt=""
            />
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            class="
              sm:px-10
              flex flex-col
              justify-center
              mt-5
              mb-8
              md:mt-0
              lg:w-5/12
            "
          >
            <h3
              class="
                mt-2
                text-2xl text-left
                md:text-center
                lg:text-left
                md:text-3xl
                lg:text-4xl
                font-black
                text-gray-700
              "
            >
              Ask your business
              <span class="gradient-blue">"anything"</span>
            </h3>

            <div
              class="
                mt-5
                md:mt-10
                lg:mt-15
                text-md
                md:text-md
                lg:text-lg
                xl:text-xl
                text-left
                md:text-center
                lg:text-left
              "
            >
              <div class="mt-1 font-black text-indigo-400">
                “How much profit am I making on my store?”
              </div>
              <div class="mt-1 font-black text-green-400">
                “What’s my total income?"
              </div>

              <div class="mt-1 font-black text-red-400">
                “What are my biggest expenses?”
              </div>

              <div class="mt-1 font-black text-yellow-400">
                “What’s going on with my cashflow?”
              </div>
            </div>
            <p
              class="
                mt-5
                md:mt-10
                lg:mt-15
                text-md
                md:text-md
                lg:text-lg
                xl:text-xl
                text-left
                md:text-center
                lg:text-left
                text-black
                mb-10
              "
            >
              It'll tell you like it is! No more generic advice. No complicated
              spreadsheets, online docs, notes or obtuse formulas. Just growth.
            </p>
            <!-- </p> -->
          </div>
        </div>
        <br />
        <br />
      </div>
    </section>
  </div>
</template>

<script>
import IntegrationCircle from "./IntegrationCircle.vue";
// import Card from "./Card.vue";
export default {
  components: {
    IntegrationCircle,
    // Card,
  },
  //   name: FeaturesSection,
};
</script>