<template>
  <section
    class="
      relative
      block
      py-20
      px-4
      max-w-screen-4xl
      overflow-hidden
      leading-6
      text-left text-indigo-900
      bg-peach
    "
  >
    <div
      class="w-full mx-auto leading-6 text-left px-6 sm:px-8 md:px-10 xl:px-12"
    >
      <div
        class="
          relative
          w-full
          leading-6
          text-left
          md:text-center
          lg:mx-auto
          xl:flex-grow-0
          lg:flex-grow-0
        "
      >
        <h1
          class="
            box-border
            mx-0
            mt-6
            mb-0
            text-3xl
            font-black
            text-gray-800
            md:text-4xl
            lg:text-5xl
            xl:text-5xl
          "
        >
          Managing a creator business can be really messy
        </h1>
        <div class="">
          <ul
            class="
              mt-6
              sm:text-lg
              md:text-2xl
              text-gray-700
              md:text-center
              text-left
            "
          >
            <li
              data-aos="fade-up"
              data-aos-duration="1000"
              class="mt-2 font-bold text-purple-400"
            >
              logging into lots of different
              <Btn color="bg-purple-400">platforms</Btn>
            </li>

            <li
              data-aos="fade-up"
              data-aos-duration="1000"
              class="font-bold text-red-400"
            >
              maintaining multiple
              <Btn color="bg-red-400"> bank accounts </Btn>
            </li>

            <li
              data-aos="fade-up"
              data-aos-duration="1000"
              class="mt-2 font-bold text-yellow-400"
            >
              using different
              <Btn color="bg-yellow-400"> credit cards </Btn>
            </li>

            <li
              data-aos="fade-up"
              data-aos-duration="1000"
              class="mt-2 font-bold text-green-400"
            >
              earning on various
              <Btn color="bg-green-400"> social media outlets </Btn>
            </li>

            <li
              data-aos="fade-up"
              data-aos-duration="1000"
              class="mt-2 font-bold text-blue-400"
            >
              organizing
              <Btn color="bg-blue-400"> spreadsheets </Btn>
            </li>

            <li
              data-aos="fade-up"
              data-aos-duration="1000"
              class="mt-2 font-bold text-indigo-400"
            >
              tracking
              <Btn color="bg-indigo-400"> receipts </Btn>
            </li>
            <!-- <li class="mt-2 font-bold text-gray-400">...</li> -->
          </ul>
        </div>
        <!-- <div class="btn">receipts</div> -->
      </div>
    </div>
  </section>
</template>

<script>
import Btn from "./Btn.vue";

export default {
  components: {
    Btn,
  },
};
</script>